import React, { useState, useEffect, useRef } from 'react';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [companiiLaAdresa, setCompaniiLaAdresa] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchRef = useRef(null);
  const searchTimeout = useRef(null);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchSuggestions = async (value) => {
    if (value.length < 2) {
      setSuggestions([]);
      return;
    }
    setSuggestionsLoading(true);
    try {
      const response = await fetch(`https://api.aipro.ro/cuie?search=${encodeURIComponent(value)}&perPage=5&searchBy=all`);
      if (!response.ok) throw new Error('Eroare la căutarea sugestiilor');
      const result = await response.json();
      setSuggestions(result.data);
    } catch (err) {
      console.error('Eroare la obținerea sugestiilor:', err);
    } finally {
      setSuggestionsLoading(false);
    }
  };

  const debouncedFetchSuggestions = (value) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }
    searchTimeout.current = setTimeout(() => {
      fetchSuggestions(value);
    }, 300);
  };

  const fetchData = async (cui) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`https://api.aipro.ro/get?cui=${cui}`);
      if (!response.ok) throw new Error('Eroare la încărcarea datelor');
      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }
      setData(result);
      await fetchCompaniiLaAdresa(result.date_generale.adresa);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompaniiLaAdresa = async (adresa) => {
    try {
      const response = await fetch(`https://api.aipro.ro/cuie?search=${encodeURIComponent(adresa)}&searchBy=address`);
      if (!response.ok) throw new Error('Eroare la căutarea companiilor la aceeași adresă');
      const result = await response.json();
      setCompaniiLaAdresa(result.data);
    } catch (err) {
      console.error('Eroare la căutarea companiilor:', err);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedFetchSuggestions(value);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion.CUI.toString());
    setSuggestions([]);
    setShowSuggestions(false);
    fetchData(suggestion.CUI);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isNaN(searchTerm)) {
      // If searchTerm is not a number, assume it's a company name
      const selectedCompany = suggestions.find(s => s.nume_companie.toLowerCase() === searchTerm.toLowerCase());
      if (selectedCompany) {
        fetchData(selectedCompany.CUI);
      } else {
        setError('Compania nu a fost găsită. Încercați să căutați după CUI.');
      }
    } else {
      fetchData(searchTerm);
    }
  };

  const handleRefresh = async () => {
    if (!data || !data.CUI) return;
    setLoading(true);
    try {
      await fetch(`https://api.aipro.ro/delete?cui=${data.CUI}`);
      await fetchData(data.CUI);
    } catch (err) {
      setError('Eroare la reîmprospătarea datelor');
    } finally {
      setLoading(false);
    }
  };

  const prepareChartData = () => {
    if (!data) return [];
    const yearKeys = Object.keys(data).filter(key => key.startsWith('an') && !isNaN(key.slice(2)));
    return yearKeys.map(yearKey => {
      const year = yearKey.slice(2);
      const yearData = data[yearKey];
      return {
        an: year,
        'Profit Net': yearData.i.find(item => item.indicator === 'I18')?.val_indicator || 0,
        'Cifra de Afaceri': yearData.i.find(item => item.indicator === 'I13')?.val_indicator || 0,
        'Datorii Totale': yearData.i.find(item => item.indicator === 'I7')?.val_indicator || 0,
        'Active Totale': yearData.i.find(item => item.indicator === 'I1')?.val_indicator + yearData.i.find(item => item.indicator === 'I2')?.val_indicator || 0,
      };
    }).sort((a, b) => a.an - b.an);
  };

  const chartData = prepareChartData();

  const getLatestYearData = () => {
    if (!chartData.length) return null;
    return chartData[chartData.length - 1];
  };

  const latestYearData = getLatestYearData();

  const preparePieChartData = () => {
    if (!latestYearData) return [];
    return [
      { name: 'Profit Net', value: latestYearData['Profit Net'] },
      { name: 'Datorii Totale', value: latestYearData['Datorii Totale'] },
      { name: 'Active Totale', value: latestYearData['Active Totale'] },
    ];
  };

  const pieChartData = preparePieChartData();

  return (
    <div style={{ padding: '20px', backgroundColor: '#f0f2f5', minHeight: '100vh', fontFamily: 'Arial, sans-serif' }}>
      <div style={{ marginBottom: '20px', padding: '20px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)' }}>
        <h1 style={{ marginBottom: '20px', color: '#1a237e' }}>Dashboard Financiar</h1>
        <form onSubmit={handleSubmit} style={{ position: 'relative' }} ref={searchRef}>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Introduceți CUI sau numele companiei"
            style={{ 
              padding: '10px', 
              borderRadius: '4px', 
              border: '1px solid #ccc', 
              width: '100%', 
              marginBottom: '10px'
            }}
          />
          {showSuggestions && (suggestionsLoading || suggestions.length > 0) && (
            <ul style={{
              position: 'absolute',
              top: '100%',
              left: 0,
              right: 0,
              backgroundColor: 'white',
              border: '1px solid #ddd',
              borderRadius: '0 0 4px 4px',
              zIndex: 1000,
              listStyle: 'none',
              padding: 0,
              margin: 0,
              maxHeight: '200px',
              overflowY: 'auto'
            }}>
              {suggestionsLoading ? (
                <li style={{ padding: '10px', textAlign: 'center' }}>Se încarcă...</li>
              ) : (
                suggestions.map((suggestion, index) => (
                  <li 
                    key={index} 
                    onClick={() => handleSuggestionClick(suggestion)}
                    style={{
                      padding: '10px',
                      cursor: 'pointer',
                      borderBottom: '1px solid #eee'
                    }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = '#f0f0f0'}
                    onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                  >
                    <strong>{suggestion.nume_companie}</strong><br />
                    CUI: {suggestion.CUI}<br />
                    Adresă: {suggestion.date_generale?.adresa || 'N/A'}
                  </li>
                ))
              )}
            </ul>
          )}
          <button 
            type="submit" 
            disabled={loading}
            style={{ 
              padding: '10px 20px', 
              backgroundColor: loading ? '#ccc' : '#1a237e', 
              color: 'white', 
              border: 'none', 
              borderRadius: '4px', 
              cursor: loading ? 'not-allowed' : 'pointer',
              transition: 'background-color 0.3s',
              width: '100%'
            }}
          >
            {loading ? 'Se încarcă...' : 'Caută'}
          </button>
        </form>
      </div>

      {error && (
        <div style={{ marginBottom: '20px', padding: '20px', backgroundColor: '#ffcdd2', color: '#b71c1c', borderRadius: '8px' }}>
          {error}
        </div>
      )}

      {data && (
        <>
          <div style={{ marginBottom: '20px', padding: '20px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <h2 style={{ color: '#1a237e' }}>Informații Companie</h2>
              <button 
                onClick={handleRefresh}
                disabled={loading}
                style={{ 
                  padding: '5px 10px', 
                  backgroundColor: loading ? '#ccc' : '#4caf50', 
                  color: 'white', 
                  border: 'none', 
                  borderRadius: '4px', 
                  cursor: loading ? 'not-allowed' : 'pointer',
                  transition: 'background-color 0.3s'
                }}
              >
                {loading ? 'Se reîmprospătează...' : 'Reîmprospătează'}
              </button>
            </div>
            <p><strong>Nume:</strong> {data.nume_companie}</p>
            <p><strong>CUI:</strong> {data.CUI}</p>
            <p><strong>Adresă:</strong> {data.date_generale.adresa}</p>
            <p><strong>Stare:</strong> {data.stare_inregistrare}</p>
            <p><strong>Cod CAEN:</strong> {data.date_generale.cod_CAEN} - {data.date_generale.denumire}</p>
          </div>

          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '20px', marginBottom: '20px' }}>
            <div style={{ padding: '20px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)' }}>
              <h2 style={{ marginBottom: '10px', color: '#1a237e' }}>Evoluție Financiară</h2>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="an" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Profit Net" stroke="#8884d8" />
                  <Line type="monotone" dataKey="Cifra de Afaceri" stroke="#82ca9d" />
                  <Line type="monotone" dataKey="Datorii Totale" stroke="#ffc658" />
                </LineChart>
              </ResponsiveContainer>
            </div>

            {latestYearData && (
              <div style={{ padding: '20px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)' }}>
                <h2 style={{ marginBottom: '10px', color: '#1a237e' }}>Indicatori Financiari ({latestYearData.an})</h2>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={[latestYearData]}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="an" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Profit Net" fill="#8884d8" />
                    <Bar dataKey="Cifra de Afaceri" fill="#82ca9d" />
                    <Bar dataKey="Datorii Totale" fill="#ffc658" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            )}

            <div style={{ padding: '20px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)' }}>
              <h2 style={{ marginBottom: '10px', color: '#1a237e' }}>Structura Financiară</h2>
              <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                  <Pie
                    data={pieChartData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {pieChartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div style={{ padding: '20px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)' }}>
            <h2 style={{ marginBottom: '10px', color: '#1a237e' }}>Companii la Aceeași Adresă</h2>
            {companiiLaAdresa.length > 0 ? (
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {companiiLaAdresa.map((companie, index) => (
                  <li 
                    key={index} 
                    style={{ 
                      marginBottom: '10px', 
                      padding: '10px', 
                      backgroundColor: '#f5f5f5', 
                      borderRadius: '4px',
                      cursor: 'pointer',
                      transition: 'background-color 0.3s'
                    }}
                    onClick={() => fetchData(companie.CUI)}
                    onMouseEnter={(e) => e.target.style.backgroundColor = '#e0e0e0'}
                    onMouseLeave={(e) => e.target.style.backgroundColor = '#f5f5f5'}
                  >
                    <strong>{companie.nume_companie}</strong> (CUI: {companie.CUI})
                  </li>
                ))}
              </ul>
            ) : (
              <p>Nu s-au găsit alte companii la această adresă.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;